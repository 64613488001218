import { IdsMainButton } from '@ids/react'
import { SeeOffersButton } from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import Layout from '@itau-loans-www/shopping/src/layouts/Summary'
import analytics from '@mobi/libraries/analytics'

import '../final-screen.scss'
import { useResult } from '../hooks'

const CGI = () => {
  useResult({})
  const proposalContext = useProposal()
  const proposal = proposalContext?.product?.proposal
  const link = proposal?.link
  const productCode = proposal?.productCode

  return (
    <Layout>
      <div className="final_screen final_screen__cgi">
        <div className="final_screen__content">
          <h1 className="final_screen__title">
            Acesse o formulário de crédito com garantia de imóvel Itaú e
            finalize seu empréstimo
          </h1>
          <br />

          <div className="final_screen__buttons-wrapper">
            <a href={link} target="_blank" rel="noreferrer">
              <IdsMainButton
                full={true}
                onClick={() => {
                  analytics.track('buttonClick', {
                    currentPage: 'telas-finais-de-redirect-do-produto',
                    detail: productCode
                  })
                }}
              >
                Acessar formulário
              </IdsMainButton>
            </a>
            <SeeOffersButton isCrossSell />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CGI
